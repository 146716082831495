import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import React from "react"
import { ThemeProvider } from "styled-components"
import { BaseToolbar } from "../../../organisms/BaseToolbar"
import { Footer } from "../../../molecules/Footer"

import theme from "../../../../gatsby-theme-material-ui-top-layout/theme"
import * as S from "./style"
import { Button, TextField } from "@mui/material"
import { useMutation } from "@apollo/client"
import { previewResourceGenerationMutation } from "../mutations"
import { Controller, useForm } from "react-hook-form"
import {
  ContentMaterials,
  EducationMaterial,
} from "../CourseLayout/ContentMaterials"
import { ContentAction } from "../CourseLayout/ContentAction"

interface ResourceInput {
  name: string
  url: string
}

const AddResourcePage: React.FC = () => {
  const intl = useIntl()
  const { register, getValues, handleSubmit, watch, control } = useForm()

  const [previewResourceGeneration, previewResourceGenerationOpts] =
    useMutation(previewResourceGenerationMutation)

  const handlePreviewResourceGeneration = () => {
    const values = getValues()
    previewResourceGeneration({
      variables: {
        name: values.name,
        url: values.url,
        urlPathScope: values.urlPathScope,
      },
    })
  }

  console.log(previewResourceGenerationOpts.data)

  const previewMaterials: EducationMaterial[] =
    previewResourceGenerationOpts.data?.previewResourceGeneration?.previewGeneration?.map(
      (item, idx) =>
        ({
          id: idx,
          name: item.text,
          websiteUrl: item.absoluteUrl,
          slug: item.absoluteUrl,
          kind: {
            id: 0,
            name: "Article",
          },
        } as EducationMaterial)
    )

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <BaseToolbar
          backText={intl.formatMessage({
            defaultMessage: "Learning Resources",
            description: "back link label",
          })}
          backLink="/resources"
        />
        {/* <form> */}
        <S.Content>
          <S.ContentActionDesktop>
            <ContentAction
              name={watch("name")}
              addToPlanUnavailable={true} // TODO: remove temporary disabled feature
              userAuthenticated={false}
              userRegistration={undefined}
              loading={false}
              onRegisterClick={() => undefined}
              onGoToLessonplanClick={() => undefined}
            />
          </S.ContentActionDesktop>
          <S.ContentMain>
            <S.ContentHeader>
              <S.Header>
                <FormattedMessage
                  defaultMessage="Add custom resource (beta feature preview)"
                  description="header"
                />
              </S.Header>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label={intl.formatMessage({
                      defaultMessage: "Name*",
                      description: "input label",
                    })}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <Controller
                name="url"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    label={intl.formatMessage({
                      defaultMessage: "Url*",
                      description: "input label",
                    })}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <Controller
                name="urlPathScope"
                control={control}
                render={({ field }) => (
                  <TextField
                    label={intl.formatMessage({
                      defaultMessage: "Url path scope",
                      description: "input label",
                    })}
                    variant="outlined"
                    {...field}
                  />
                )}
              />
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handlePreviewResourceGeneration()}
                disabled={!watch("name") || !watch("url")}
              >
                <FormattedMessage
                  defaultMessage="Generate preview"
                  description="button label"
                />
              </Button>
            </S.ContentHeader>
            <S.ContentActionMobile>
              <ContentAction
                name={watch("name")}
                userAuthenticated={false}
                userRegistration={undefined}
                loading={false}
                onRegisterClick={() => undefined}
                onGoToLessonplanClick={() => undefined}
              />
            </S.ContentActionMobile>
            {previewMaterials?.length > 0 && (
              <S.ContentMaterials>
                <ContentMaterials educationMaterials={previewMaterials} />
              </S.ContentMaterials>
            )}
          </S.ContentMain>
        </S.Content>
        {/* </form> */}
        <S.Footer>
          <Footer />
        </S.Footer>
      </S.Wrapper>
    </ThemeProvider>
  )
}
export { AddResourcePage }
