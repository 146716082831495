import React from "react"
import { SEO } from "../components/atoms/SEO"
import { AddResourcePage } from "../components/sets/home/AddResourcePage"

const Ressources: React.FC = () => {
  return (
    <>
      <SEO />
      <AddResourcePage />
    </>
  )
}

export default Ressources
