import { Link } from "gatsby-plugin-react-intl"
import React from "react"
import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
`

export const Content = styled.div`
  min-height: 100%;
  padding: 0 24px;
  margin: 30px 0;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;
  grid-template-areas: "contentMain" "contentAction";

  @media screen and (min-width: 800px) {
    margin: 30px 5%;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "contentMain contentAction";
  }
  @media screen and (min-width: 1200px) {
    margin: 30px 10%;
  }
`

export const ContentMain = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-auto-rows: min-content;
  grid-area: contentMain;
  grid-template-areas: "contentHeader" "contentAction" "contentMaterials";

  @media screen and (min-width: 800px) {
    grid-template-areas: "contentHeader" "contentMaterials";
  }
`

export const ContentHeader = styled.div`
  grid-area: contentHeader;
  display: grid;
  grid-gap: 24px;
`
export const Header = styled.h1``

export const ContentActionMobile = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-auto-rows: min-content;
  grid-area: contentAction;

  @media screen and (min-width: 800px) {
    display: none;
  }
`

export const ContentActionDesktop = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-auto-rows: min-content;
  grid-area: contentAction;

  display: none;
  @media screen and (min-width: 800px) {
    display: block;
  }
`

export const ContentMaterials = styled.div``

export const Footer = styled.div`
  margin: 30px 0 0 0;
`
